import styled from '@emotion/styled';
import _ from 'lodash';

import { PSection } from 'src/interface/section';
import { ITags } from 'src/interface/service';
import { Media } from 'src/interface/media';
import SectionHeader from 'src/components/sections/section-header';
import useResponsive from 'src/utils/devices';
import { convertObjPath } from 'src/utils/helper';
import useSWR from 'swr';
import { GET } from 'src/services';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import SectionFullTabs from 'src/components/sections/section-full-tab';
import { SHOW_COMMEMORATE } from 'src/constants';
import React from 'react';
import CardV from 'src/components/cards/card-v';
import CardH from 'src/components/cards/card-h';
import SectionOneCard from '../section-card/section-one';
type Props = {
  block1?: any;
  block2?: any
  block3?: PSection['block2'];
  block4?: PSection['block3'];
  dataTags?: { data: ITags[] };
  eventDataLayer?: IDataLayerGlobal;
  dataSoft?: any;
};

const SectionThree = ({ block1, block2, eventDataLayer }: Props) => {
  const responsive: Media = useResponsive();
  const payload: any = {
    two: {
      block1: 6,
      block2: 6,
      block3: 6
    }
  };
  const fetcher = (url: any) => GET(url);
  const { data } = useSWR(`/api/v1.0/home/section-two${convertObjPath(payload.two)}`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  });


  // DESKTOP
  const _block1 = block1;
  const _block2 = block2;
  const _block3 = data && data?.block1;
  const block4 = data && data?.block2;



  // MOBILE
  let cloneDataList: any = [_block1, _block2, _block3];
  let cloneData = _.filter([{ ..._block1 }, { ..._block2 }, { ..._block3 }], n => !_.isEmpty(n.data));

  return (
    <>
      <SectionWrapper id={`section-4`} className={`container wrapper-1`} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        {responsive.isTabletPro && (
          <>
            {_.map(cloneData, (itemlist, index) => (
              <React.Fragment key={index}>
                <div className={`block-${index + 1}-w1`}>
                  <SectionHeader bgColor='var(--primary-color);' data={itemlist?.title} link={itemlist?.link} />
                  <div className='card-list desktop'>
                    {_.map(itemlist?.data?.slice(0, 1), (item, i) => (
                      <div className='card-item' key={i}>
                        <CardV
                          data={item}
                          showBadge={false}
                          showBlurb={false}
                          showCenter={true}
                          showByline={false}
                          showFooter={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            ...eventDataLayer,
                            data: {
                              block: `${index + 1}`,
                              title: item?.title,
                              heading: itemlist?.title,
                              index: i + 1,
                              ...eventDataLayer?.data
                            }
                          }}
                        />
                      </div>
                    ))}
                    {_.map(itemlist?.data?.slice(1, 4), (item, i) => (
                      <div className='card-item' key={i}>
                        <CardH
                          data={item}
                          showBadge={false}
                          showBlurb={false}
                          showByline={true}
                          showFooter={true}
                          // ! DATALAYER
                          eventDataLayer={{
                            ...eventDataLayer,
                            data: {
                              block: `${index + 1}`,
                              title: item?.title,
                              heading: itemlist?.title,
                              index: i + 2,
                              ...eventDataLayer?.data
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </>
        )}

        {responsive.isTabletMini && (
          <>
            {!_.isEmpty(cloneDataList) && <SectionFullTabs
              data={cloneDataList}
              designTwo={true}
              // ! DATALAYER
              eventDataLayer={{ ...eventDataLayer }}
            />}
          </>
        )}
      </SectionWrapper>

      <SectionWrapper id='section-5' className='wrapper-2' >
        <SectionOneCard
          settrade={true}
          data={block4}
          // ! DATALAYER
          eventDataLayer={{
            ...eventDataLayer,
            position: 'ใต้:section-4',
            section: 'section-5',
            data: {
              block: '1',
              heading: block4?.title
            }
          }}
        />
      </SectionWrapper>

    </>

  );
};

export default SectionThree;
const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  padding: 30px 15px !important;
  .carousel-wrapper {
    overflow: hidden;
  }
  ul{
    flex-wrap: wrap;
  }
  &.wrapper-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .block-1-w1,
    .block-2-w1,
    .block-3-w1 {
      .card-list {
        margin-top: 10px;
        display: grid;
        grid-gap: 10px;
      }
    }
  }
`;

