import styled from '@emotion/styled';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { IArticle } from 'src/interface/section';
import _ from 'lodash';
import { useState } from 'react';
import CardFull from 'src/components/cards/card-full';
import SectionHeader from './section-header';
import Carousels from 'src/components/carousels';
import CardV from 'src/components/cards/card-v';
import WidgetBackline from '../widget/widget-backlink';

type Props = {
  data: IArticle & IArticle[];
  eventDataLayer?: IDataLayerGlobal;
  designOne?: boolean;
  designTwo?: boolean;
};
const SectionFullTabs = ({ data, eventDataLayer, designOne = false, designTwo = false }: Props) => {
  if (_.isEmpty(data)) return null;
  const filteredData = data.filter(e => e !== null);

  const [dataItem, setDataItem]: any = useState(filteredData[0]);
  const [active, setActive] = useState({
    key: 0,
    status: true
  });
  const resDataItem = dataItem || filteredData[0];

  return (
    <>
      <SectionWrapper>
        <SectionHeader tabs={true} data={filteredData} setDataItem={setDataItem} setActive={setActive} dataItem={resDataItem} active={active} />

        <div className='grid-areas'>
          {designTwo && (
            <div className='card-list'>
              {_.map(resDataItem?.data, (element, index) => (
                <CardV
                  key={index}
                  data={element}
                  showCenter={true}
                  showBlurb={false}
                  showFooter={false}
                  showBadge={false}
                  // ! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    data: {
                      title: element?.title,
                      heading: resDataItem?.title,
                      index: index + 1,
                      ...eventDataLayer?.data
                    }
                  }}
                />
              ))}
            </div>
          )}
          {designOne && (
            <Carousels infinite={false} dots={true} mobileSlideShow={1}>
              {_.map(resDataItem?.data, (element, index) => (
                <CardFull
                  key={index}
                  data={element}
                  showBlurb={false}
                  showFooter={false}
                  showBadge={false}
                  // ! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    data: {
                      title: element?.title,
                      heading: resDataItem?.title,
                      carousel: index + 1,
                      ...eventDataLayer?.data
                    }
                  }}
                />
              ))}
            </Carousels>
          )}
        </div>
      </SectionWrapper>

      <WidgetBackline link={resDataItem?.link} />
    </>
  );
};

export default SectionFullTabs;

const SectionWrapper = styled.section`
  .grid-areas {
    margin-top: 10px;
    .card-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
  }
`;
